import type { AuthedLoginService } from 'human-api/src/services/login/install';
import type { LoginService } from 'human-api/src/services/login/login_proto';
import type { NativeCompatabilityService } from 'human-api/src/services/native/native_proto';
import React from 'react';

import type { ConfigFlags } from '@/features/base/config_flags/install';
import type { InstallPageResults } from '@/features/base/page_types';
import type { IdentityService } from '@/services/identity/identity_service';
import fakeExportPdf from '@/services/share/fakes/minimal.pdf';

import { CameraRollService } from './services/camera_roll/camera_roll_service';
import type { ShareService } from './services/share/share_service';

function cache<T>(target: () => T) {
  let cached: null | { value: T } = null;
  return () => {
    if (cached == null) {
      const value = target();
      cached = { value };
      return value;
    }
    return cached.value;
  };
}

// TODO: Replace prototype cache with decorators when nextjs supports
// See: https://github.com/vercel/next.js/issues/48360
//
// function withCache<T>(target: () => T, context: DecoratorContext): () => T {
//   if (context.kind === 'getter') {
//     return cache(target);
//   }
//   throw new Error('withCache decorator is only applicable to getter functions.');
// }

/**
 * Convert a record of promised values to a record of resolved values.
 */
type AwaitedPromiseRecord<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends Promise<infer U> ? U : T[K];
};

/**
 * Convert a record of promised values to a promise of a record of resolved values.
 */
type LiftRecordWithPromises<T extends Record<string, unknown>> = Promise<
  AwaitedPromiseRecord<T>
>;

/**
 * Helper function to parallelize an record of promises and return a record of resolved values.
 * @param promises
 */
async function parallel<T extends Record<string, unknown>>(
  promises: T
): LiftRecordWithPromises<T> {
  const keys = Object.keys(promises);
  const results = await Promise.all(Object.values(promises));
  return results.reduce<Record<string, unknown>>((acc, resolved, index) => {
    acc[keys[index]] = resolved;
    return acc;
  }, {}) as unknown as LiftRecordWithPromises<T>;
}

/**
 * Helper function to get awaited value from promised record.
 * @param input
 * @param key
 */
async function getAwaited<
  T extends Promise<Record<string, unknown>>,
  K extends keyof Awaited<T>,
>(input: T, key: K): Promise<Awaited<T>[K]> {
  const v = await Promise.resolve(input);
  return v[key];
}

type RecordOfPromises<T> = {
  [K in keyof T]: Promise<T[K]> | T[K];
};

/**
 * Helper to parallelize download of install files and their relevant dependencies.
 */
async function runParallel<
  K extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<K, (d: any) => any>,
>(
  install: Promise<T>,
  key: K,
  deps: RecordOfPromises<Parameters<T[K]>[0]>
): Promise<Awaited<ReturnType<T[K]>>> {
  const promisedDeps = parallel(deps);
  const promisedInstall = getAwaited(install, key);
  return promisedInstall.then(async (fn) => fn(await promisedDeps));
}

export function createDependencies() {
  class Dependencies {
    private static _instance: Dependencies | null = null;

    static get instance(): Dependencies {
      if (!Dependencies._instance) {
        Dependencies._instance = new Dependencies();
        // Hack to update all getters to be cached. Should be replaced with decorators once next js supports.
        for (const [key, desc] of Object.entries(
          Object.getOwnPropertyDescriptors(Dependencies.prototype)
        )) {
          if (desc.set) continue;
          if (!desc.get) continue;
          Object.defineProperty(Dependencies.instance, key, {
            ...desc,
            get: cache(desc.get),
          });
        }
      }
      return Dependencies._instance;
    }

    get app(): Promise<{
      App: React.ComponentType<{ children: React.ReactNode }>;
    }> {
      return runParallel(import('@/features/base/install'), 'installApp', {
        auth: Dependencies.instance.auth,
        isSSR: getAwaited(Dependencies.instance.bootstrap, 'isSSR'),
        account: Dependencies.instance.account,
        errorService: Dependencies.instance.errorService,
        blobService: Dependencies.instance.blobService,
        splashService: Dependencies.instance.splashService,
        liveUpdateFeature: Dependencies.instance.liveUpdateFeature,
        appService: Dependencies.instance.appService,
        layoutStore: Dependencies.instance.layoutStore,
        deviceCapabilitiesStore: Dependencies.instance.deviceCapabilitiesStore,
        notificationsPresenter: getAwaited(
          Dependencies.instance.notifications,
          'presenter'
        ),
        navigation: Dependencies.instance.navigationService,
        QueryStringRoutes: Dependencies.instance.queryStringRouter,
        nativeCapabilityService: Dependencies.instance.nativeCapabilityService,
        analyticsPresenter: Dependencies.instance.analyticsPresenter,
        interactionPresenter: Dependencies.instance.interactionFeature,
        screenshotAnalyticsInit: Dependencies.instance.screenshotAnalytics,
        deepLinkPresenter: Dependencies.instance.deepLinkFeature,
      });
    }

    get configFlags(): Promise<ConfigFlags> {
      return import('@/features/base/config_flags/install').then(
        async ({ installConfigFlags }) =>
          installConfigFlags({
            enableUrlFlagOverrides: (await Dependencies.instance.bootstrap)
              .enableUrlFlagOverrides,
          })
      );
    }

    get featureFlags() {
      return runParallel(
        import('@/features/base/feature_flags/install'),
        'installFlags',
        {
          appService: Dependencies.instance.appService,
          authenticationPresenter: getAwaited(
            Dependencies.instance.auth,
            'presenter'
          ),
          featureFlagService: Dependencies.instance.featureFlagsService,
          userDataStorage: Dependencies.instance.userDataStorage,
          enableUrlFlagOverrides: getAwaited(
            Dependencies.instance.bootstrap,
            'enableUrlFlagOverrides'
          ),
        }
      );
    }

    get bootstrap() {
      return import('@/features/base/bootstrap/create').then(
        ({ createDefaultBootstrap }) => {
          return createDefaultBootstrap();
        }
      );
    }

    get queryStringRouter(): Promise<React.ComponentType> {
      return runParallel(
        import('@/features/base/query_string_router'),
        'installQueryStringRouter',
        {
          dependencies: Dependencies.instance,
          statusBarService: Dependencies.instance.statusBarService,
        }
      );
    }

    get fallbackRouter(): InstallPageResults {
      return import('@/features/base/fallback_router').then(
        async ({ installFallbackRouter }) => {
          return installFallbackRouter({ dependencies: Dependencies.instance });
        }
      );
    }

    /**
     * PAGES
     */

    get login() {
      return runParallel(
        import('@/features/login/install'),
        'installLoginPage',
        {
          isSSR: getAwaited(Dependencies.instance.bootstrap, 'isSSR'),
          authFeature: Dependencies.instance.auth,
          accountSettingsStorage: Dependencies.instance.accountSettingsStorage,
          accountFeature: Dependencies.instance.account,
          PageStructure: Dependencies.instance.pageStructure,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          browserService: Dependencies.instance.browserService,
          errorService: Dependencies.instance.errorService,
          identityService: Dependencies.instance.identityService,
        }
      );
    }

    get catalogHealthEventForm() {
      return runParallel(
        import('@/features/health_events/catalog/install'),
        'installCatalogHealthEventsForm',
        {
          accountFeature: Dependencies.instance.account,
          healthRecordService: Dependencies.instance.healthRecordService,
          catalogRepository: Dependencies.instance.catalogRepository,
          LockFeature: getAwaited(
            Dependencies.instance.plusUpsell,
            'LockFeature'
          ),
          blobService: Dependencies.instance.blobService,
          downloadAndSharePresenter:
            Dependencies.instance.downloadAndSharePresenter,
          uploadService: Dependencies.instance.uploadService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          errorService: Dependencies.instance.errorService,
        }
      );
    }

    get customHealthEventForm() {
      return runParallel(
        import('@/features/health_events/custom/install'),
        'installCustomHealthEventsForm',
        {
          accountFeature: Dependencies.instance.account,
          errorService: Dependencies.instance.errorService,
          healthRecordService: Dependencies.instance.healthRecordService,
          LockFeature: getAwaited(
            Dependencies.instance.plusUpsell,
            'LockFeature'
          ),
          blobService: Dependencies.instance.blobService,
          downloadAndSharePresenter:
            Dependencies.instance.downloadAndSharePresenter,
          uploadService: Dependencies.instance.uploadService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get searchHealthEvents() {
      return runParallel(
        import('@/features/health_events/search/install'),
        'installSearchHealthEvents',
        {
          accountFeature: Dependencies.instance.account,
          errorService: Dependencies.instance.errorService,
          catalogService: Dependencies.instance.catalogService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get home() {
      return runParallel(import('@/features/home/install'), 'installHomePage', {
        PageStructure: Dependencies.instance.pageStructure,
        accountFeature: Dependencies.instance.account,
        interactionPresenter: Dependencies.instance.interactionFeature,
        errorService: Dependencies.instance.errorService,
        clipboardService: Dependencies.instance.clipboardService,
        healthRecordService: Dependencies.instance.healthRecordService,
        profileService: Dependencies.instance.profileService,
        surveyService: Dependencies.instance.surveyService,
        symptomService: Dependencies.instance.symptomService,
        symptomRepository: Dependencies.instance.symptomRepository,
        analyticsPresenter: Dependencies.instance.analyticsPresenter,
        notificationsFeature: Dependencies.instance.notifications,
        ratingNudgePresenter: Dependencies.instance.ratingNudge,
        planService: Dependencies.instance.planService,
        navigationService: getAwaited(
          Dependencies.instance.navigationService,
          'NavigationService'
        ),
        storyService: Dependencies.instance.storyService,
        assistantService: Dependencies.instance.assistantService,
        noteService: Dependencies.instance.noteService,
        blobService: Dependencies.instance.blobService,
        deeplinkPresenter: Dependencies.instance.deepLinkFeature,
        subscriptionsPresenter: Dependencies.instance.subscriptions,
        userDataStorage: Dependencies.instance.userDataStorage,
      });
    }

    get insights() {
      return runParallel(
        import('@/features/insights/install'),
        'installInsightsPage',
        {
          PageStructure: Dependencies.instance.pageStructure,
          accountFeature: Dependencies.instance.account,
          hapticsService: Dependencies.instance.hapticsService,
          surveyService: Dependencies.instance.surveyService,
          planService: Dependencies.instance.planService,
          noteService: Dependencies.instance.noteService,
          symptomRepository: Dependencies.instance.symptomRepository,
          errorService: Dependencies.instance.errorService,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          interactionPresenter: Dependencies.instance.interactionFeature,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get medicalHistoryAddConditions() {
      return runParallel(
        import('@/features/medical_history_flow/add_conditions/install'),
        'installAddConditionPage',
        {
          symptomService: Dependencies.instance.symptomService,
          healthRecordService: Dependencies.instance.healthRecordService,
          conditionRepository: Dependencies.instance.conditionRepository,
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get medicalHistoryAddSymptoms() {
      return runParallel(
        import('@/features/medical_history_flow/add_symptoms/install'),
        'installAddSymptomPage',
        {
          symptomService: Dependencies.instance.symptomService,
          healthRecordService: Dependencies.instance.healthRecordService,
          symptomRepository: Dependencies.instance.symptomRepository,
          conditionRepository: Dependencies.instance.conditionRepository,
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          errorService: Dependencies.instance.errorService,
        }
      );
    }

    get medicalHistorySetChronicSymptoms() {
      return runParallel(
        import('@/features/medical_history_flow/set_chronic_symptoms/install'),
        'installSetChronicSymptoms',
        {
          healthRecordService: Dependencies.instance.healthRecordService,
          symptomRepository: Dependencies.instance.symptomRepository,
          notificationsFeature: Dependencies.instance.notifications,
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          interactionPresenter: Dependencies.instance.interactionFeature,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
        }
      );
    }

    get mindfulMoment() {
      return runParallel(
        import('@/features/mindful_moment/install'),
        'installMindfulMomentPage',
        {
          accountFeature: Dependencies.instance.account,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get onboarding() {
      return runParallel(
        import('@/features/onboarding/install'),
        'installOnboardingPage',
        {
          accountFeature: Dependencies.instance.account,
          PageStructure: Dependencies.instance.pageStructure,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          authFeature: Dependencies.instance.auth,
          errorService: Dependencies.instance.errorService,
          profileService: Dependencies.instance.profileService,
          identityService: Dependencies.instance.identityService,
          appService: Dependencies.instance.appService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          interactionPresenter: Dependencies.instance.interactionFeature,
        }
      );
    }

    get plan() {
      return runParallel(import('@/features/plan/install'), 'installPlanPage', {
        PageStructure: Dependencies.instance.pageStructure,
        planService: Dependencies.instance.planService,
        accountFeature: Dependencies.instance.account,
        analyticsPresenter: Dependencies.instance.analyticsPresenter,
        navigationService: getAwaited(
          Dependencies.instance.navigationService,
          'NavigationService'
        ),
        hapticsService: Dependencies.instance.hapticsService,
        userDataStorage: Dependencies.instance.userDataStorage,
        interactionPresenter: Dependencies.instance.interactionFeature,
        syncNotifications: getAwaited(
          Dependencies.instance.planNotifications,
          'syncNotifications'
        ),
      });
    }

    get planNotifications() {
      return runParallel(
        import('@/features/plan_notifications/install'),
        'installPlanNotifications',
        {
          userDataStorage: Dependencies.instance.userDataStorage,
          planService: Dependencies.instance.planService,
          notificationsPresenter: getAwaited(
            Dependencies.instance.notifications,
            'presenter'
          ),
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get planManagement() {
      return runParallel(
        import('@/features/plan_management/install'),
        'installPlanManagementPage',
        {
          PageStructure: Dependencies.instance.pageStructure,
          planService: Dependencies.instance.planService,
          authPresenter: getAwaited(Dependencies.instance.auth, 'presenter'),
          accountFeature: Dependencies.instance.account,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          statusBarService: Dependencies.instance.statusBarService,
          syncNotifications: getAwaited(
            Dependencies.instance.planNotifications,
            'syncNotifications'
          ),
        }
      );
    }

    get symptomManagement() {
      return runParallel(
        import('@/features/symptom_management/install'),
        'installSymptomManagementPage',
        {
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          healthRecordService: Dependencies.instance.healthRecordService,
          accountFeature: Dependencies.instance.account,
          symptomRepository: Dependencies.instance.symptomRepository,
          symptomService: Dependencies.instance.symptomService,
        }
      );
    }

    get practitionerVerification() {
      return runParallel(
        import('@/features/onboarding/practitioner/verification/install'),
        'installPractitionerVerification',
        {
          PageStructure: Dependencies.instance.pageStructure,
          accountFeature: Dependencies.instance.account,
          authPresenter: getAwaited(Dependencies.instance.auth, 'presenter'),
          profileService: Dependencies.instance.profileService,
          errorService: Dependencies.instance.errorService,
          uploadService: Dependencies.instance.uploadService,
          statusBarService: Dependencies.instance.statusBarService,
        }
      );
    }

    get testResults() {
      return runParallel(
        import('@/features/test_results/install'),
        'installTestResultsForm',
        {
          accountFeature: Dependencies.instance.account,
          healthRecordService: Dependencies.instance.healthRecordService,
          errorService: Dependencies.instance.errorService,
          LockFeature: getAwaited(
            Dependencies.instance.plusUpsell,
            'LockFeature'
          ),
          blobService: Dependencies.instance.blobService,
          downloadAndSharePresenter:
            Dependencies.instance.downloadAndSharePresenter,
          uploadService: Dependencies.instance.uploadService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get timeline() {
      return runParallel(
        import('@/features/timeline/install'),
        'installTimelinePage',
        {
          PageStructure: Dependencies.instance.pageStructure,
          authPresenter: getAwaited(Dependencies.instance.auth, 'presenter'),
          accountFeature: Dependencies.instance.account,
          eventLogService: Dependencies.instance.eventLogService,
          planService: Dependencies.instance.planService,
          symptomRepository: Dependencies.instance.symptomRepository,
          conditionRepository: Dependencies.instance.conditionRepository,
          noteService: Dependencies.instance.noteService,
          surveyService: Dependencies.instance.surveyService,
          errorService: Dependencies.instance.errorService,
          blobService: Dependencies.instance.blobService,
          healthRecordService: Dependencies.instance.healthRecordService,
          catalogRepository: Dependencies.instance.catalogRepository,
          downloadAndSharePresenter:
            Dependencies.instance.downloadAndSharePresenter,
          uploadService: Dependencies.instance.uploadService,
          LockFeature: getAwaited(
            Dependencies.instance.plusUpsell,
            'LockFeature'
          ),
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    /**
     * SHARED PAGE FEATURES
     */

    get account() {
      return runParallel(
        import('@/features/account/install'),
        'installAccountFeature',
        {
          authFeature: Dependencies.instance.auth,
          sessionRefresher: Dependencies.instance.sessionRefresher,
          profileService: Dependencies.instance.profileService,
          withFeatureFlags: getAwaited(
            Dependencies.instance.featureFlags,
            'withFeatureFlags'
          ),
          errorService: Dependencies.instance.errorService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          userDataStorage: Dependencies.instance.userDataStorage,
          // TODO(Alex) kill this dep. Once BE is ready, we can get product/tier/entitlements from the session.
          // For now, it is modelled in localstorage inside subscriptionsPresenter
          subscriptionsPresenter: Dependencies.instance.subscriptions,
          skipPrepareHumanScreen: false,
        }
      );
    }

    get accountSettings() {
      return runParallel(
        import('@/features/account_settings/install'),
        'installAccountSettings',
        {
          PageStructure: Dependencies.instance.pageStructure,
          authFeature: Dependencies.instance.auth,
          storage: Dependencies.instance.accountSettingsStorage,
          browserService: Dependencies.instance.browserService,
          accountFeature: Dependencies.instance.account,
          profileService: Dependencies.instance.profileService,
          errorService: Dependencies.instance.errorService,
          statusBarService: Dependencies.instance.statusBarService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          clipboardPresenter: Dependencies.instance.clipboardFeature,
          subscriptionsPresenter: Dependencies.instance.subscriptions,
        }
      );
    }

    get clipboardFeature() {
      return runParallel(
        import('@/features/clipboard/install'),
        'installClipboardFeature',
        {
          clipboardService: Dependencies.instance.clipboardService,
        }
      );
    }

    get downloadAndSharePresenter() {
      return runParallel(
        import('@/features/download_share/install'),
        'installDownloadAndShare',
        {
          shareService: Dependencies.instance.shareService,
          cameraRollService: Dependencies.instance.cameraRollService,
          errorService: Dependencies.instance.errorService,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
        }
      );
    }

    get notes() {
      return runParallel(import('@/features/notes/install'), 'installNotes', {
        PageStructure: Dependencies.instance.pageStructure,
        accountFeature: Dependencies.instance.account,
        noteService: Dependencies.instance.noteService,
        blobService: Dependencies.instance.blobService,
        symptomRepository: Dependencies.instance.symptomRepository,
        navigationService: getAwaited(
          Dependencies.instance.navigationService,
          'NavigationService'
        ),
        errorService: Dependencies.instance.errorService,
      });
    }

    get notificationSettings() {
      return runParallel(
        import('@/features/account_settings/notification_settings/install'),
        'installNotificationSettings',
        {
          PageStructure: Dependencies.instance.pageStructure,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          accountFeature: Dependencies.instance.account,
          notificationsPresenter: getAwaited(
            Dependencies.instance.notifications,
            'presenter'
          ),
          notificationsService: Dependencies.instance.notificationsService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get analyticsPresenter() {
      return runParallel(
        import('@/features/analytics/install'),
        'installAnalyticsClient',
        {
          authFeature: Dependencies.instance.auth,
          analyticsService: Dependencies.instance.analyticsService,
          analyticsStorage: Dependencies.instance.analyticsStorage,
          errorService: Dependencies.instance.errorService,
          appsFlyerService: Dependencies.instance.appsFlyerService,
          appService: Dependencies.instance.appService,
          webBundleVersion: getAwaited(
            Dependencies.instance.configFlags,
            'webBundleVersion'
          ),
          debug: process.env.NODE_ENV === 'development',
        }
      );
    }

    get auth() {
      return runParallel(
        import('@/features/auth/install'),
        'installAuthFeature',
        {
          loginService: Dependencies.instance.loginService,
          authedLoginService: Dependencies.instance.authedLoginService,
          signupService: Dependencies.instance.signupService,
          authSessionStorage: Dependencies.instance.authSessionStorage,
          analyticsStorage: Dependencies.instance.analyticsStorage,
          errorService: Dependencies.instance.errorService,
        }
      );
    }

    get sessionRefresher() {
      return runParallel(
        import('@/features/session_refresher/install'),
        'installSessionRefresher',
        {
          loginService: Dependencies.instance.loginService,
          authSessionStorage: Dependencies.instance.authSessionStorage,
        }
      );
    }

    get interactionFeature() {
      return runParallel(
        import('@/features/interaction/install'),
        'installInteractionsFeature',
        {
          interactionService: Dependencies.instance.interactionService,
          userDataStorage: Dependencies.instance.userDataStorage,
          authSessionStorage: Dependencies.instance.authSessionStorage,
        }
      );
    }

    get notifications() {
      return runParallel(
        import('@/features/notifications/install'),
        'installNotificationsFeature',
        {
          authFeature: Dependencies.instance.auth,
          notificationsService: Dependencies.instance.notificationsService,
          planService: Dependencies.instance.planService,
          notificationsDeviceService:
            Dependencies.instance.notificationsDeviceService,
          errorService: Dependencies.instance.errorService,
          deeplinkPresenter: Dependencies.instance.deepLinkFeature,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          notificationsStorage: Dependencies.instance.notificationsStorage,
        }
      );
    }

    get deepLinkFeature() {
      return runParallel(
        import('@/features/base/deeplink/install'),
        'installDeeplinkFeature',
        {
          appsFlyerService: Dependencies.instance.appsFlyerService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          surveyService: Dependencies.instance.surveyService,
          errorService: Dependencies.instance.errorService,
          authenticationPresenter: getAwaited(
            Dependencies.instance.auth,
            'presenter'
          ),
        }
      );
    }

    get accountSettingsStorage() {
      return import('@/ui/settings/storage').then(
        async ({ installAccountSettingsStorage }) => {
          return installAccountSettingsStorage(
            (await Dependencies.instance.bootstrap).isSSR
          );
        }
      );
    }

    get notificationsStorage() {
      return import('@/features/notifications/storage').then(
        async ({ installNotificationsStorage }) => {
          return installNotificationsStorage(
            (await Dependencies.instance.bootstrap).isSSR
          );
        }
      );
    }

    get pageStructure() {
      return runParallel(
        import('@/features/base/page_structure/install'),
        'installApplicationPageStructure',
        {
          accountFeature: Dependencies.instance.account,
          statusBar: Dependencies.instance.statusBarService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get plusDowngrade() {
      return runParallel(
        import('@/features/subscriptions/downgrade/install'),
        'installPlusDowngrade',
        {
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get plusPass() {
      return runParallel(
        import('@/features/subscriptions/plus_pass/install'),
        'installPlusPass',
        {
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          accountFeature: Dependencies.instance.account,
          subscriptionsPresenter: Dependencies.instance.subscriptions,
          interactionPresenter: Dependencies.instance.interactionFeature,
          browserService: Dependencies.instance.browserService,
          errorService: Dependencies.instance.errorService,
        }
      );
    }

    get plusUpsell() {
      return runParallel(
        import('@/features/subscriptions/upsell/install'),
        'installPlusUpsell',
        {
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          accountFeature: Dependencies.instance.account,
          subscriptionsPresenter: Dependencies.instance.subscriptions,
          errorService: Dependencies.instance.errorService,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          clipboardPresenter: Dependencies.instance.clipboardFeature,
          browser: Dependencies.instance.browserService,
          appService: Dependencies.instance.appService,
        }
      );
    }

    get plusValueJourney() {
      return runParallel(
        import('@/features/subscriptions/plus_value_journey/install'),
        'installPlusValueJourney',
        {
          accountFeature: Dependencies.instance.account,
          subscriptionsPresenter: Dependencies.instance.subscriptions,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          appService: Dependencies.instance.appService,
        }
      );
    }

    get plusWelcome() {
      return runParallel(
        import('@/features/subscriptions/plus_welcome/install'),
        'installPlusWelcome',
        {
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          accountFeature: Dependencies.instance.account,
        }
      );
    }

    get privacyPromise() {
      return runParallel(
        import('@/features/privacy_promise/install'),
        'installPrivacyPromisePage',
        {
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          browser: Dependencies.instance.browserService,
        }
      );
    }

    get profileSettings() {
      return runParallel(
        import('@/features/profile_settings/install'),
        'installProfileSettingsFeature',
        {
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get profileSettingsCareTeam() {
      return runParallel(
        import('@/features/profile_settings/care_team/install'),
        'installCareTeamPage',
        {
          profileService: Dependencies.instance.profileService,
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get profileSettingsHelp() {
      return runParallel(
        import('@/features/profile_settings/help/install'),
        'installProfileHelpPage',
        {
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          browserService: Dependencies.instance.browserService,
          clipboardPresenter: Dependencies.instance.clipboardFeature,
        }
      );
    }

    get profileSettingsHelpContactUs() {
      return runParallel(
        import('@/features/profile_settings/contact_us/install'),
        'installContactUsPage',
        {
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          helpService: Dependencies.instance.helpService,
          appService: Dependencies.instance.appService,
        }
      );
    }

    get profileSettingsManageConditions() {
      return runParallel(
        import('@/features/profile_settings/manage_conditions/install'),
        'installManageConditionsPage',
        {
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          symptomService: Dependencies.instance.symptomService,
          healthRecordService: Dependencies.instance.healthRecordService,
          conditionRepository: Dependencies.instance.conditionRepository,
        }
      );
    }

    get profileSettingsMedicalInfo() {
      return runParallel(
        import('@/features/profile_settings/medical_info/install'),
        'installMedicalInfoPage',
        {
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get profileSettingsProfileInfo() {
      return runParallel(
        import('@/features/profile_settings/profile_info/install'),
        'installProfileInfoPage',
        {
          profileService: Dependencies.instance.profileService,
          errorService: Dependencies.instance.errorService,
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get ratingNudge() {
      return runParallel(
        import('@/features/base/rating_nudge/install'),
        'installRatingNudge',
        {
          appService: Dependencies.instance.appService,
          inAppReviewService: Dependencies.instance.inAppReviewService,
          nativeCapabilityService:
            Dependencies.instance.nativeCapabilityService,
          authenticationPresenter: getAwaited(
            Dependencies.instance.auth,
            'presenter'
          ),
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          userDataStorage: Dependencies.instance.userDataStorage,
        }
      );
    }

    get screenshotAnalytics() {
      return runParallel(
        import('@/features/screenshot/install'),
        'installScreenshotAnalytics',
        {
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          screenshotService: Dependencies.instance.screenshotService,
        }
      );
    }

    get survey() {
      return runParallel(
        import('@/features/survey/install'),
        'installSurveyFeature',
        {
          surveyService: Dependencies.instance.surveyService,
          interactionPresenter: Dependencies.instance.interactionFeature,
          symptomRepository: Dependencies.instance.symptomRepository,
          accountFeature: Dependencies.instance.account,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          errorService: Dependencies.instance.errorService,
          notificationsFeature: Dependencies.instance.notifications,
          hapticsService: Dependencies.instance.hapticsService,
        }
      );
    }

    get addATreatmentFlow() {
      return runParallel(
        import('@/features/add_treatment_flow/install'),
        'installAddTreatmentFlow',
        {
          planService: Dependencies.instance.planService,
          treatmentService: Dependencies.instance.treatmentService,
          accountFeature: Dependencies.instance.account,
          errorService: Dependencies.instance.errorService,
          statusBar: Dependencies.instance.statusBarService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          userDataStorage: Dependencies.instance.userDataStorage,
          syncNotifications: getAwaited(
            Dependencies.instance.planNotifications,
            'syncNotifications'
          ),
        }
      );
    }

    get editSymptomScoreFlow(): InstallPageResults<{
      symptomId?: string;
      answerId?: string;
      score?: string;
    }> {
      return runParallel(
        import('@/features/edit_symptom_score_flow/install'),
        'installEditSymptomScoreFlow',
        {
          accountFeature: Dependencies.instance.account,
          noteService: Dependencies.instance.noteService,
          hapticsService: Dependencies.instance.hapticsService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          symptomRepository: Dependencies.instance.symptomRepository,
          surveyService: Dependencies.instance.surveyService,
          errorService: Dependencies.instance.errorService,
          blobService: Dependencies.instance.blobService,
          uploadService: Dependencies.instance.uploadService,
          downloadAndSharePresenter:
            Dependencies.instance.downloadAndSharePresenter,
          LockFeature: getAwaited(
            Dependencies.instance.plusUpsell,
            'LockFeature'
          ),
        }
      );
    }

    get editPlanTemplateFlow(): InstallPageResults<{
      templateId?: string;
      templateFormMode?: string;
    }> {
      return runParallel(
        import('@/features/edit_plan_template_flow/install'),
        'installEditPlanTemplateFlow',
        {
          accountFeature: Dependencies.instance.account,
          planService: Dependencies.instance.planService,
          errorService: Dependencies.instance.errorService,
          userDataStorage: Dependencies.instance.userDataStorage,
          syncNotifications: getAwaited(
            Dependencies.instance.planNotifications,
            'syncNotifications'
          ),
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get inAppContent(): InstallPageResults {
      return runParallel(
        import('@/features/in_app_content/install'),
        'installInAppContent',
        {
          accountFeature: Dependencies.instance.account,
          errorService: Dependencies.instance.errorService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          browserService: Dependencies.instance.browserService,
          assistantService: Dependencies.instance.assistantService,
          catalogService: Dependencies.instance.catalogService,
          deeplinkPresenter: Dependencies.instance.deepLinkFeature,
        }
      );
    }

    get globalAdd() {
      return runParallel(
        import('@/features/global_add/install'),
        'installGlobalAdd',
        {
          healthRecordService: Dependencies.instance.healthRecordService,
          symptomRepository: Dependencies.instance.symptomRepository,
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get liveUpdateFeature() {
      return runParallel(
        import('@/features/live_updates/install'),
        'installLiveUpdateFeature',
        {
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          appService: Dependencies.instance.appService,
          splashService: Dependencies.instance.splashService,
          errorService: Dependencies.instance.errorService,
          liveUpdateService: Dependencies.instance.liveUpdateService,
          userDataStorage: Dependencies.instance.userDataStorage,
          enableCapgoLiveUpdates: getAwaited(
            Dependencies.instance.configFlags,
            'enableCapgoLiveUpdates'
          ),
          liveUpdateManifest: getAwaited(
            Dependencies.instance.configFlags,
            'liveUpdateManifest'
          ),
          isSSR: getAwaited(Dependencies.instance.bootstrap, 'isSSR'),
        }
      );
    }

    get logNote(): InstallPageResults<{ noteId?: string }> {
      return runParallel(
        import('@/features/log_note/install'),
        'installLogNotePage',
        {
          accountFeature: Dependencies.instance.account,
          noteService: Dependencies.instance.noteService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          healthRecordService: Dependencies.instance.healthRecordService,
          symptomService: Dependencies.instance.symptomService,
          surveyService: Dependencies.instance.surveyService,
          hapticsService: Dependencies.instance.hapticsService,
          errorService: Dependencies.instance.errorService,
          blobService: Dependencies.instance.blobService,
          uploadService: Dependencies.instance.uploadService,
          downloadAndSharePresenter:
            Dependencies.instance.downloadAndSharePresenter,
          LockFeature: getAwaited(
            Dependencies.instance.plusUpsell,
            'LockFeature'
          ),
        }
      );
    }

    get logSymptomFlow() {
      return runParallel(
        import('@/features/log_symptom_flow/install'),
        'installLogSymptomFlow',
        {
          accountFeature: Dependencies.instance.account,
          surveyService: Dependencies.instance.surveyService,
          blobService: Dependencies.instance.blobService,
          uploadService: Dependencies.instance.uploadService,
          errorService: Dependencies.instance.errorService,
          downloadAndSharePresenter:
            Dependencies.instance.downloadAndSharePresenter,
          LockFeature: getAwaited(
            Dependencies.instance.plusUpsell,
            'LockFeature'
          ),
          healthRecordService: Dependencies.instance.healthRecordService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          hapticsService: Dependencies.instance.hapticsService,
          symptomService: Dependencies.instance.symptomService,
          noteService: Dependencies.instance.noteService,
          symptomRepository: Dependencies.instance.symptomRepository,
          conditionRepository: Dependencies.instance.conditionRepository,
        }
      );
    }

    get missedTreatmentsFlow() {
      return runParallel(
        import('@/features/missed_treatments_flow/install'),
        'installMissedTreatmentsFlow',
        {
          planService: Dependencies.instance.planService,
          hapticsService: Dependencies.instance.hapticsService,
          accountFeature: Dependencies.instance.account,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          errorService: Dependencies.instance.errorService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
        }
      );
    }

    get export() {
      return runParallel(
        import('@/features/export/install'),
        'installExportPage',
        {
          accountFeature: Dependencies.instance.account,
          symptomRepository: Dependencies.instance.symptomRepository,
          conditionRepository: Dependencies.instance.conditionRepository,
          catalogRepository: Dependencies.instance.catalogRepository,
          surveyService: Dependencies.instance.surveyService,
          noteService: Dependencies.instance.noteService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          eventLogService: Dependencies.instance.eventLogService,
          planService: Dependencies.instance.planService,
          healthRecordService: Dependencies.instance.healthRecordService,
          exportService: Dependencies.instance.exportService,
          shareService: Dependencies.instance.shareService,
          errorService: Dependencies.instance.errorService,
          appService: Dependencies.instance.appService,
          storyService: Dependencies.instance.storyService,
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          LockFeature: getAwaited(
            Dependencies.instance.plusUpsell,
            'LockFeature'
          ),
        }
      );
    }

    get planNotificationsPrompt() {
      return runParallel(
        import('@/features/plan_notification_prompt/install'),
        'installPlanNotificationPrompt',
        {
          accountFeature: Dependencies.instance.account,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          notificationsFeature: Dependencies.instance.notifications,
        }
      );
    }

    get subscriptions() {
      return runParallel(
        import('@/features/subscriptions/install'),
        'installSubscriptionsFeature',
        {
          appService: Dependencies.instance.appService,
          paymentService: Dependencies.instance.paymentService,
          authSessionPresenter: getAwaited(
            Dependencies.instance.auth,
            'presenter'
          ),
          appsflyerService: Dependencies.instance.appsFlyerService,
          navigationService: getAwaited(
            Dependencies.instance.navigationService,
            'NavigationService'
          ),
          errorService: Dependencies.instance.errorService,
          sessionRefresher: Dependencies.instance.sessionRefresher,
          isSSR: getAwaited(Dependencies.instance.bootstrap, 'isSSR'),
          userDataStorage: Dependencies.instance.userDataStorage,
          enableFakePurchaseFlow: getAwaited(
            Dependencies.instance.configFlags,
            'enableFakePurchaseFlow'
          ),
          subscriptionsService: Dependencies.instance.subscriptionsService,
        }
      );
    }

    /**
     * SERVICES
     */

    get analyticsStorage() {
      return import('@/features/analytics/storage').then(
        async ({ installAnalyticsStorage }) => {
          return installAnalyticsStorage(
            (await Dependencies.instance.bootstrap).isSSR
          );
        }
      );
    }

    get analyticsService() {
      return runParallel(
        import('human-api/src/services/analytics/install'),
        'installAnalyticsService',
        {
          httpService: Dependencies.instance.baseHttpService,
          errorService: Dependencies.instance.errorService,
          useFakes: Dependencies.instance.bootstrap.then(
            (bootstrap) =>
              bootstrap.useFakes ||
              bootstrap.errorServiceConfig.type === 'local-fake'
          ),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get appService() {
      return runParallel(
        import('@/services/app/install'),
        'installAppService',
        {
          webBundleVersion: getAwaited(
            Dependencies.instance.configFlags,
            'webBundleVersion'
          ),
        }
      );
    }

    get appsFlyerService() {
      return runParallel(
        import('@/services/appsflyer/install'),
        'installAppsFlyerService',
        {
          config: getAwaited(
            Dependencies.instance.bootstrap,
            'appsFlyerConfig'
          ),
          debug: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          errorService: Dependencies.instance.errorService,
        }
      );
    }

    get assistantService() {
      return runParallel(
        import('human-api/src/services/assistant/install'),
        'installAssistantService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get authSessionStorage() {
      return import('@/services/storage/auth_session_store/install').then(
        async ({ installAuthSessionStorage }) => {
          return installAuthSessionStorage(
            (await Dependencies.instance.bootstrap).isSSR
          );
        }
      );
    }

    get blobService() {
      return runParallel(
        import('human-api/src/services/blob/install'),
        'installBlobService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get browserService() {
      return import('@/services/browser/install').then(
        async ({ installBrowserService }) => {
          return installBrowserService();
        }
      );
    }

    get cameraRollService(): Promise<CameraRollService> {
      return runParallel(
        import('@/services/camera_roll/install'),
        'installCameraRollService',
        {
          isSSR: getAwaited(Dependencies.instance.bootstrap, 'isSSR'),
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          appService: Dependencies.instance.appService,
        }
      );
    }

    get catalogService() {
      return runParallel(
        import('human-api/src/services/catalog/install'),
        'installCatalogService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get clipboardService() {
      return import('@/services/clipboard/install').then(
        async ({ installClipboardService }) => {
          return installClipboardService();
        }
      );
    }

    get deviceCapabilitiesStore() {
      return import('@/features/base/device_capabilities/install').then(
        async ({ installDeviceCapabilities }) => {
          return installDeviceCapabilities();
        }
      );
    }

    get errorService() {
      return runParallel(
        import('@/services/error/install'),
        'installErrorService',
        {
          authSession: Dependencies.instance.authSessionStorage,
          errorServiceConfig: getAwaited(
            Dependencies.instance.bootstrap,
            'errorServiceConfig'
          ),
          appService: Dependencies.instance.appService,
        }
      );
    }

    get eventLogService() {
      return runParallel(
        import('human-api/src/services/eventlog/install'),
        'installEventLogService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get exportService() {
      return runParallel(
        import('human-api/src/services/export/install'),
        'installExportService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
          fakePdfUrl: fakeExportPdf,
        }
      );
    }

    get featureFlagsService() {
      return runParallel(
        import('human-api/src/services/featureflags/install'),
        'installFeatureFlagsService',
        {
          errorService: Dependencies.instance.errorService,
          httpService: Dependencies.instance.baseHttpService,
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
        }
      );
    }

    get hapticsService() {
      return import('@/services/haptics_service/install').then(
        async ({ installHapticsService }) => {
          return installHapticsService();
        }
      );
    }

    get healthRecordService() {
      return runParallel(
        import('human-api/src/services/healthrecord/install'),
        'installHealthRecordService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
          fakeHealthRecords: Dependencies.instance.bootstrap.then(
            (b) => b.fakeAccountData?.healthRecords
          ),
        }
      );
    }

    get helpService() {
      return runParallel(
        import('human-api/src/services/help/install'),
        'installHelpService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get inAppReviewService() {
      return import('@/services/in_app_review/install').then(
        ({ installInAppReviewService }) => {
          return installInAppReviewService();
        }
      );
    }

    // This http service will never refresh the user session
    // It should only be used by loginService & signupService & firebaseAuthService & analyticService & featureflagsService
    get baseHttpService() {
      return runParallel(
        import('human-api/src/services/http/install'),
        'installHttpService',
        {
          authTokenProvider: Dependencies.instance.authSessionStorage,
          webBundleVersion: getAwaited(
            Dependencies.instance.configFlags,
            'webBundleVersion'
          ),
        }
      );
    }

    // NOTE: This is a hack, we currently are experiencing issues where FE is sending expired tokens to
    // unauthenticated endpoints and BE is returning 401s. This is a temporary fix explicitly for the NativeCompatabilityService.
    // NativeCompatabilityService has methods that are called on launch, before we have revalidated tokens in session storage,
    // As such they are 401'ing and we are potentially not showing the upgrade banner.
    // We MUST NOT use this service for any other RPC's without thinking hard about the implications. For example, if
    // we used this for all anonymous endpoints, we would loss session context in logs, and potentially effect
    // the results returned for authenticated users (eg: feature flags, and analytic events).
    get anonymousHttpService() {
      return runParallel(
        import('human-api/src/services/http/install'),
        'installHttpService',
        {
          authTokenProvider: { token: null },
          webBundleVersion: getAwaited(
            Dependencies.instance.configFlags,
            'webBundleVersion'
          ),
        }
      );
    }

    get httpService() {
      return runParallel(
        import('@/services/http_with_auth/install'),
        'installHttpWithAuthService',
        {
          refreshAuthPresenter: Dependencies.instance.sessionRefresher,
          httpService: Dependencies.instance.baseHttpService,
        }
      );
    }

    get interactionService() {
      return runParallel(
        import('human-api/src/services/interaction/install'),
        'installInteractionService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get subscriptionsService() {
      return runParallel(
        import('human-api/src/services/subscriptions/install'),
        'installSubscriptionsService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get layoutStore() {
      return import('@/features/base/layout_store/install').then(
        async ({ installLayoutStore }) => {
          return installLayoutStore();
        }
      );
    }

    get liveUpdateService() {
      return runParallel(
        import('@/services/live_updates/install'),
        'installLiveUpdateService',
        {
          enableCapgoLiveUpdates: getAwaited(
            Dependencies.instance.configFlags,
            'enableCapgoLiveUpdates'
          ),
          errorService: Dependencies.instance.errorService,
        }
      );
    }

    get loginService(): Promise<Omit<LoginService, 'selectProfile'>> {
      return runParallel(
        import('human-api/src/services/login/install'),
        'installLoginService',
        {
          httpService: Dependencies.instance.baseHttpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
          fakeLoginData: getAwaited(
            Dependencies.instance.bootstrap,
            'fakeAccountData'
          ),
        }
      );
    }

    get authedLoginService(): Promise<AuthedLoginService> {
      return runParallel(
        import('human-api/src/services/login/install'),
        'installLoginService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
          fakeLoginData: getAwaited(
            Dependencies.instance.bootstrap,
            'fakeAccountData'
          ),
        }
      );
    }

    get nativeCapabilityService(): Promise<NativeCompatabilityService> {
      return runParallel(
        import('human-api/src/services/native/install'),
        'installNativeCompatibilityService',
        {
          httpService: Dependencies.instance.anonymousHttpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get navigationService() {
      return import('@/services/navigation/install').then(
        async ({ installNavigationService }) => {
          return installNavigationService();
        }
      );
    }

    get noteService() {
      return runParallel(
        import('human-api/src/services/note/install'),
        'installNoteService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
          fakeNotes: Dependencies.instance.bootstrap.then(
            (b) => b.fakeAccountData?.notes
          ),
        }
      );
    }

    get notificationsService() {
      return runParallel(
        import('@/services/notifications/install'),
        'installNotificationsRpcService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get notificationsDeviceService() {
      return runParallel(
        import('@/services/notifications/install'),
        'installNotificationsDeviceService',
        {
          errorService: Dependencies.instance.errorService,
          appService: Dependencies.instance.appService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
        }
      );
    }

    get paymentService() {
      return runParallel(
        import('@/services/payment/install'),
        'installPaymentService',
        {
          appService: Dependencies.instance.appService,
          isSSR: getAwaited(Dependencies.instance.bootstrap, 'isSSR'),
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          revenueCatConfig: getAwaited(
            Dependencies.instance.bootstrap,
            'revenueCatConfig'
          ),
          enableFakePurchaseFlow: getAwaited(
            Dependencies.instance.configFlags,
            'enableFakePurchaseFlow'
          ),
        }
      );
    }

    get planService() {
      return runParallel(
        import('human-api/src/services/plan/install'),
        'installPlanService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get profileService() {
      return runParallel(
        import('human-api/src/services/profile/install'),
        'installProfileService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
          fakeProfileData: getAwaited(
            Dependencies.instance.bootstrap,
            'fakeAccountData'
          ),
        }
      );
    }

    get screenshotService() {
      return runParallel(
        import('@/services/screenshot/install'),
        'installScreenshotService',
        {
          appService: Dependencies.instance.appService,
          isSSR: getAwaited(Dependencies.instance.bootstrap, 'isSSR'),
        }
      );
    }

    get signupService() {
      return runParallel(
        import('human-api/src/services/signup/install'),
        'installSignupService',
        {
          httpService: Dependencies.instance.baseHttpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get splashService() {
      return runParallel(
        import('@/services/splash/install'),
        'installSplashService',
        {
          updateFlags: getAwaited(
            Dependencies.instance.featureFlags,
            'updateFlags'
          ),
        }
      );
    }

    get statusBarService() {
      return import('@/services/status_bar_service/install').then(
        async ({ installStatusBarService }) => {
          return installStatusBarService();
        }
      );
    }

    get storyService() {
      return runParallel(
        import('human-api/src/services/story/install'),
        'installStoryService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get surveyService() {
      return runParallel(
        import('human-api/src/services/survey/install'),
        'installSurveyService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
          fakeProfileIdWithoutSurvey: Dependencies.instance.bootstrap.then(
            (b) => b.fakeAccountData?.profiles[0].id
          ),
        }
      );
    }

    get symptomService() {
      return runParallel(
        import('human-api/src/services/symptom/install'),
        'installSymptomService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get treatmentService() {
      return runParallel(
        import('human-api/src/services/treatment/install'),
        'installTreatmentService',
        {
          httpService: Dependencies.instance.httpService,
          errorService: Dependencies.instance.errorService,
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          apiDomain: getAwaited(Dependencies.instance.bootstrap, 'apiDomain'),
        }
      );
    }

    get uploadService() {
      return runParallel(
        import('@/services/upload/install'),
        'installUploadService',
        {
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
        }
      );
    }

    get identityService(): Promise<IdentityService> {
      return runParallel(
        import('@/services/identity/install'),
        'installIdentityService',
        {
          appService: Dependencies.instance.appService,
          authenticationPresenter: getAwaited(
            Dependencies.instance.auth,
            'presenter'
          ),
          analyticsPresenter: Dependencies.instance.analyticsPresenter,
          firebaseConfig: getAwaited(
            Dependencies.instance.bootstrap,
            'firebaseConfig'
          ),
          isSSR: getAwaited(Dependencies.instance.bootstrap, 'isSSR'),
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
        }
      );
    }

    get shareService(): Promise<ShareService> {
      return runParallel(
        import('@/services/share/install'),
        'installShareService',
        {
          isSSR: getAwaited(Dependencies.instance.bootstrap, 'isSSR'),
          useFakes: getAwaited(Dependencies.instance.bootstrap, 'useFakes'),
          appService: Dependencies.instance.appService,
        }
      );
    }

    get userDataStorage() {
      return runParallel(
        import('@/services/storage/userdata_store/install'),
        'installUserDataStorage',
        {
          isSSR: getAwaited(Dependencies.instance.bootstrap, 'isSSR'),
        }
      );
    }

    /**
     * Repositories
     */
    get catalogRepository() {
      return runParallel(
        import('@/repositories/catalog/install'),
        'installCatalogRepository',
        {
          catalogService: Dependencies.instance.catalogService,
        }
      );
    }

    get conditionRepository() {
      return runParallel(
        import('@/repositories/condition/install'),
        'installConditionRepository',
        {
          symptomService: Dependencies.instance.symptomService,
        }
      );
    }

    get symptomRepository() {
      return runParallel(
        import('@/repositories/symptom/install'),
        'installSymptomRepository',
        {
          symptomService: Dependencies.instance.symptomService,
        }
      );
    }
  }
  return Dependencies;
}

export const Dependencies = createDependencies().instance;

export type Dependencies = typeof Dependencies;
export type PageDependencyKeys = {
  [K in keyof Dependencies]: Dependencies[K] extends InstallPageResults
    ? K
    : never;
}[keyof Dependencies];

export type PageDependencies = Pick<Dependencies, PageDependencyKeys>;
